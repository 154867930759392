"use client";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../resources/images/logo.webp";

function NavBar() {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const hamburger = document.querySelector("#hamburger");
    const navbar = document.querySelector("#navlinks");
    const overlay = document.querySelector("#layer");

    const handleClick = () => {
      if (navbar && overlay) {
        navbar.classList.toggle("navbar-active");
        overlay.classList.toggle("overlay-active");
        hamburger.classList.toggle("is-active");
      }
    };

    if (hamburger) {
      hamburger.addEventListener("click", handleClick);
    }

    return () => {
      if (hamburger) {
        hamburger.removeEventListener("click", handleClick);
      }
    };
  }, []);

  return (
    <nav
      id="navbar"
      className="sticky top-0 z-20 border-b border-gray-100 bg-white backdrop-blur dark:border-gray-700/30 dark:bg-gray-900/80"
    >
      <div className="mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
        <div className="relative flex flex-wrap items-center justify-between gap-6 lg:gap-0 lg:py-4">
          <div className="relative z-20 flex w-full justify-between md:px-0 lg:w-max items-center">
            <div className="w-[150px] h-auto cursor-pointer">
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </div>

            <button
              aria-label="hamburger"
              className="relative lg:hidden flex justify-items-end justify-end w-[40px]"
            >
              <div id="hamburger" className="my-6">
                <div
                  aria-hidden="true"
                  className=" h-0.5  w-5 rounded bg-sky-900 transition duration-300 dark:bg-gray-300"
                ></div>
                <div
                  aria-hidden="true"
                  className=" mt-2 h-0.5 w-5 rounded bg-sky-900 transition duration-300 dark:bg-gray-300"
                ></div>
              </div>
            </button>
          </div>
          <div
            id="layer"
            aria-hidden="true"
            className="fixed inset-0 z-10 h-screen w-auto origin-bottom scale-y-0 bg-white/70 backdrop-blur-2xl transition dark:bg-gray-900/70 lg:hidden"
          ></div>
          <div
            id="navlinks"
            className="invisible absolute top-full left-0 z-20 w-full origin-top-right translate-y-1 scale-90 flex-col flex-wrap justify-end gap-6 rounded-3xl border border-gray-100 bg-white p-8 opacity-0 shadow-2xl shadow-gray-600/10 transition-all duration-300 dark:border-gray-700 dark:bg-gray-800 dark:shadow-none lg:visible lg:relative lg:flex lg:w-auto lg:translate-y-0 lg:scale-100 lg:flex-row lg:items-center lg:gap-0 lg:border-none lg:bg-transparent lg:p-0 lg:opacity-100 lg:shadow-none lg:peer-checked:translate-y-0 dark:lg:bg-transparent"
          >
            <div className="text-black dark:text-gray-300 lg:pr-4">
              <ul className="space-y-6 text-base font-medium tracking-wide lg:flex lg:space-y-0 lg:text-sm">
                <li>
                  <a
                    href="/"
                    className={
                      pathname === "/"
                        ? "block text-[#007A77] transition dark:text-primaryLight md:px-4"
                        : "md:px-4"
                    }
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="/about-us"
                    className={
                      pathname === "/about-us"
                        ? "block text-[#007A77] transition dark:text-primaryLight md:px-4"
                        : "md:px-4"
                    }
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy-policy"
                    className={
                      pathname === "/privacy-policy"
                        ? "block text-[#007A77] transition dark:text-primaryLight md:px-4"
                        : "md:px-4"
                    }
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="/user-guide"
                    className={
                      pathname === "/user-guide"
                        ? "block text-[#007A77] transition dark:text-primaryLight md:px-4"
                        : "md:px-4"
                    }
                  >
                    User Guide
                  </a>
                </li>
              </ul>
            </div>

            <div className="sign-in-section mt-12 -ml-1 flex w-full flex-col space-y-2 border-primary/10 dark:border-gray-700 sm:flex-row sm:space-y-0 sm:space-x-2 md:w-max lg:mt-0 lg:border-l lg:pl-6">
              <a
                href="/sign-in"
                className="relative ml-auto flex h-9 items-center justify-center before:absolute before:inset-0 before:rounded-full before:border before:border-gray-200 before:bg-[#007A77] before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-600 dark:before:bg-gray-700 max-lg:before:border-2 max-lg:dark:before:border max-md:w-full sm:px-4 lg:dark:before:border-gray-700 lg:dark:before:bg-gray-800"
              >
                <span className="relative text-sm font-semibold text-white dark:text-white">
                  Sign In
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
